<template>
  <v-tooltip :disabled="hide_tooltip" bottom>
    <template v-slot:activator="{ on }">
      <template v-if="show">
        <div :class="classWrapper">
          <v-icon v-if="simple" v-on="on" :color="color">{{ icon }}</v-icon>
          <span v-else class="increment" :class="classIncrement" v-on="on">
            <div class="d-flex justify-start align-center">
              <v-avatar :color="avatar_color" :size="avatar_size" class="mr-1">
                <v-icon :color="color">{{ icon }}</v-icon>
              </v-avatar>
              <span
                v-if="
                  difference_value &&
                    difference_value !== 0 &&
                    difference_value !== undefined
                "
                style="margin-left: -2px;"
                :class="color + '--text'"
                v-html="real_absolute ? difference_text : percent_text"
              >
              </span>
            </div>
          </span>
        </div>
      </template>
    </template>
    <span
      class="text-center justify-center align-center"
      v-html="
        '<span style=\'text-align:center;\'>' +
          $t(period) +
          ': <b>' +
          tooltip_text +
          '</b></span>'
      "
    ></span>
  </v-tooltip>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format_kpi_value } from "@/utils.js";

export default {
  name: "a-increment",
  props: {
    kpi: {
      required: true,
      type: String,
      default: ""
    },
    value: {
      type: Number,
      nullable: true,
      required: false,
      default: undefined
    },
    vs_value: {
      type: Number,
      nullable: true,
      required: false,
      default: undefined
    },
    period: {
      type: String,
      required: false,
      default: undefined
    },
    simple: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    hide_tooltip: {
      type: Boolean,
      default: false
    },
    shorten: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      l: {}
    };
  },
  computed: {
    show() {
      return (
        this.value !== undefined &&
        this.value !== null &&
        this.vs_value !== undefined &&
        this.vs_value !== null
      );
    },
    type() {
      let type = this.kpi_type;
      switch (type) {
        case ("number1", "percent1", "money1"):
          type = "number";
          break;
        case "percent1":
          type = "percent";
          break;
      }
      return type;
    },
    increment_value() {
      // Represents the difference between the two values in percentage
      let increment_value = 0;
      // Depending on it's kpi type, that can be a percent or a number
      if (this.real_absolute) {
        increment_value = this.difference_value;
      } else {
        increment_value = this.percent_value;
      }
      return increment_value;
    },
    percent_value() {
      // Represents the difference between the two values in percentage
      let percent_value = 0;
      // Depending on it's kpi type, that can be a percent or a number
      if (this.type === "percent") {
        percent_value = this.difference_value;
      } else {
        percent_value = (this.difference_value / Math.abs(this.vs_value)) * 100;
      }
      return percent_value;
    },
    difference_value() {
      // Represents the difference between the two values in absolute terms
      let difference_value = 0;
      difference_value = this.value - this.vs_value;
      return difference_value;
    },
    percent_text() {
      return format_kpi_value(
        this.percent_value,
        "percent1",
        this.l,
        this.shorten
      );
    },
    difference_text() {
      return format_kpi_value(
        this.difference_value,
        this.kpi_type,
        this.l,
        this.shorten
      );
    },
    tooltip_text() {
      return format_kpi_value(this.vs_value, this.kpi_type, this.l);
    },
    sense() {
      return this.percent_value > 0
        ? "up"
        : this.percent_value < 0
        ? "down"
        : "equal";
    },
    kpi_data() {
      return this.get_kpi(this.kpi);
    },
    kpi_type() {
      return this.kpi_data.data_format;
    },
    icon() {
      var icon = "mdi-equal";

      if (this.percent_value > 0 && this.percent_value <= 1) {
        icon = "mdi-chevron-up";
      }
      if (this.percent_value > 1 && this.percent_value <= 10) {
        icon = "mdi-chevron-double-up";
      }
      if (this.percent_value > 10) {
        icon = "mdi-chevron-triple-up";
      }

      if (this.percent_value < 0 && this.percent_value >= -1) {
        icon = "mdi-chevron-down";
      }
      if (this.percent_value < -1 && this.percent_value >= -10) {
        icon = "mdi-chevron-double-down";
      }
      if (this.percent_value < -10) {
        icon = "mdi-chevron-triple-down";
      }
      return icon;
    },
    avatar_color() {
      return this.sense == "up"
        ? "#e8f9ea"
        : this.sense == "down"
        ? "#fdf2f2"
        : "grey lighten-4";
    },
    color() {
      return this.sense == "up"
        ? "green"
        : this.sense == "down"
        ? "red"
        : "grey";
    },
    classIncrement() {
      let clas = "";
      return clas;
    },
    avatar_size() {
      return this.x_large
        ? 20
        : this.large
        ? 24
        : this.small
        ? 20
        : this.x_small
        ? 20
        : this.xx_small
        ? 20
        : 20;
    },
    real_absolute() {
      if (this.vs_value === 0) {
        return true;
      } else {
        return this.absolute;
      }
    },
    classWrapper() {
      if (!this.isAKpiParent) {
        return "kpi-wrapper-2";
      }
      return "ml-1";
    },
    isAKpiParent() {
      return (
        this.$parent.$options._componentTag === "a-kpi" ||
        this.$parent.$options._componentTag === "aKpi" ||
        this.$parent.$options._componentTag === "a-kpi-columns"
      );
    },
    ...mapState({
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      get_kpi: "get_kpi"
    })
  },
  mounted() {
    this.l = { ...this.$store.getters.current_hotel_language };
  }
};
</script>
<style lang="scss">
.kpi-wrapper-2 {
  //Default size
  gap: 0.2em;

  .increment {
    font-size: 0.7em;
    line-height: 1.2em;
    letter-spacing: -1px;
    .v-icon {
      font-size: 1em;
      line-height: 1.5em;
    }
  }

  &.x-large {
    .increment {
      font-size: 0.5em;
      line-height: 1em;
      letter-spacing: -1px;
    }
  }
  &.large {
    .increment {
      font-size: 0.55em;
      line-height: 1.7em;
      letter-spacing: -0.9px;
      .v-icon {
        line-height: 0.4em;
      }
    }
  }
  &.small {
    .increment {
      font-size: 0.8em;
      line-height: 0.8em;
      letter-spacing: -0.6px;
      .v-icon {
        line-height: 0.8em;
      }
    }
  }
  &.x-small {
    .increment {
      font-size: 1em;
      line-height: 1em;
      letter-spacing: -0.5px;
      .v-icon {
        line-height: 1em;
      }
    }
  }

  &.vertical {
    .increment {
      // line-height: 0.5em;
    }
    &.x-large {
      .increment {
        // margin-top: 10px;
      }
    }
    &.large {
      .increment {
        // line-height: 20px;
      }
    }
    &.small {
      .increment {
        // line-height: 20px;
      }
    }
    &.x-small {
      .increment {
        // line-height: 20px;
      }
    }
  }
}
</style>
