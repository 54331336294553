<template>
  <div :class="clase">
    <slot name="prepend"> </slot>
    <template v-if="!hide_kpi">
      <span
        class="a-kpi"
        v-html="format_kpi_value(value)"
        :data-value="value"
        :class="classIncrement"
      ></span>
    </template>
    <template v-if="vs_values && vs_values.length > 0">
      <div class="d-flex">
        <a-increment
          v-for="(vs_value, index) in vs_values"
          :key="index"
          :value="value"
          :vs_value="vs_value"
          :period="null"
          :kpi="kpi"
          :simple="simple"
          :absolute="absolute"
          :vertical="vertical"
          :shorten="shorten"
          :hide_tooltip="hide_tooltip"
        >
        </a-increment>
      </div>
    </template>
    <slot name="right"></slot>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format_kpi_value } from "@/utils.js";
import aIncrement from "@/components/rms_ui/aIncrement";

export default {
  name: "a-kpi",
  components: {
    aIncrement
  },
  data() {
    return {
      l: {}
    };
  },
  props: {
    kpi: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      nullable: true,
      required: false
    },
    vs_values: {
      type: Array,
      required: false
    },
    vs_periods: {
      type: Array,
      required: false
    },
    locale: {
      type: Object,
      required: false
    },
    absolute: {
      type: Boolean,
      required: false
    },
    clas: {
      type: String,
      required: false
    },
    simple: {
      type: Boolean,
      required: false
    },
    hide_kpi: {
      type: Boolean,
      required: false
    },
    hide_tooltip: {
      type: Boolean,
      required: false
    },
    vertical: {
      type: Boolean,
      required: false
    },
    left: {
      type: Boolean,
      required: false
    },
    right: {
      type: Boolean,
      required: false
    },
    large: {
      type: Boolean,
      required: false
    },
    x_large: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      required: false
    },
    x_small: {
      type: Boolean,
      required: false
    },
    xx_small: {
      type: Boolean,
      required: false
    },
    shorten: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    format_kpi_value(v) {
      const locale =
        this.locale && Object.keys(this.locale).length > 0
          ? this.locale
          : this.l;
      return format_kpi_value(v, this.kpi_type, locale);
    }
  },
  computed: {
    classIncrement() {
      let clas = "";
      if (this.vs_values) {
        if (this.vertical) {
          clas = "mb-1";
        } else {
          clas = "mr-1";
        }
      }

      // if (this.increment_value == 0 && this.vertical) {
      //   clas = "mt-2";
      // } else if (this.increment_value == 0 && !this.vertical) {
      //   clas = "ml-0";
      // }

      return clas;
    },
    increment_value() {
      let increment_value = 0;
      // Must calculate the percent of difference between the two values,
      // depending on it's kpi type, that can be a percent or a number
      if (this.vs_value) {
        if (this.kpi_type === "percent") {
          increment_value = this.difference;
        } else {
          increment_value = this.difference / this.vs_value;
        }
      }
      return increment_value;
    },
    difference() {
      let difference = 0;
      // Must calculate difference between the two values,
      if (this.value && this.vs_value) {
        difference = this.value - this.vs_value;
      }
      return difference;
    },
    kpi_data() {
      return this.get_kpi(this.kpi);
    },
    kpi_type() {
      return this.kpi_data.data_format;
    },
    size_class() {
      return this.x_large
        ? "x-large"
        : this.large
        ? "large"
        : this.small
        ? "small"
        : this.x_small
        ? "x-small"
        : this.xx_small
        ? "xx-small"
        : "";
    },
    clase() {
      let classes = [
        "kpi-wrapper-2",
        this.left
          ? "justify-start"
          : this.right
          ? "justify-end"
          : "justify-center",
        "align-center",
        this.size_class
      ];
      if (this.clas) {
        classes.push(this.clas);
      }
      if (this.vertical) {
        classes.push("vertical");
      }
      if (this.vs_periods && this.vs_periods.length > 0) {
        classes.push("periods");
      }

      return classes.join(" ");
    },
    ...mapState({
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      get_kpi: "get_kpi"
    })
  },
  mounted() {
    this.l = { ...this.$store.getters.current_hotel_language };
  }
};
</script>

<style lang="scss">
.kpi-wrapper-2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; //Medium by default
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.5px;

  &.vertical {
    display: grid;
    flex-direction: column;
    justify-items: center;
    &.justify-end {
      justify-items: end;
    }
    &.justify-start {
      justify-items: start;
    }
  }
  &.x-large {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: -2px;
  }
  &.large {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -1px;
  }
  &.small {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.3px;
  }
  &.x-small {
    font-size: 13px;
    line-height: 13px;
  }
  &.xx-small {
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    letter-spacing: 0;
  }
  .a-kpi {
    display: inline-block;
  }

  .symbol {
    font-size: 0.85em;
  }
}
</style>
